import { Row, Col } from "antd"
import AOS from 'aos';
import 'aos/dist/aos.css';
import styles from "./styles.module.scss"
import PhoneImg from '../../assets/images/phone.png'
import AboutImg1 from '../../assets/images/about/01.png'
import AboutImg2 from '../../assets/images/about/02.png'
import AboutImg3 from '../../assets/images/about/03.png'
import AboutImg4 from '../../assets/images/about/04.png'
import AboutImg5 from '../../assets/images/about/05.png'
import AboutImg6 from '../../assets/images/about/06.png'
import icon1 from '../../assets/images/governance.png'
import icon2 from '../../assets/images/dao.png'
import icon3 from '../../assets/images/web3.0/defineBadge.png'
import icon4 from '../../assets/images/web3.0/nftBased.png'
import icon5 from '../../assets/images/web3.0/share.png'
import { useState } from "react"
AOS.init();
export const DetailBodyV3 = () => {
  const [active, setActive] = useState(0)

  const dealActive = (active) => {
    setActive(active)
  }

  return (
    <div className={styles.container}>
      <div className={styles.common_dao} data-aos="fade-up" data-aos-duration="1500">
        <div className={styles.common_row} style={{ marginBottom: 26 }}>
          <div className={styles.common_title}>$DFA-DeFine Platform Governance Token</div>
          <div className={styles.common_content}>DFA is the native token for DeFine, the social NFT platform for all creators.</div>
        </div>

        <div className={styles.common_row} >
          <Row gutter={64}>
            <Col xs={24} md={12}>
              <div className={`df mb20`}>
                <img width={40} src={icon1}></img>
                <div className={`fs24 fw700 pl20`}>Governance</div>
              </div>
              <p>DFA token holders staking DFA on DeFine can submit proposals and vote on important decisions on the platform including new features, usage of transaction fees, token reward allocations, auction mechanics, etc. Platform governance directly affects how creators and users interact across the platform. Additionally, community governance will facilitate ecosystem development decisions of which will be funded by the DeFine NFT DAO.</p>
            </Col>
            <Col xs={24} md={12}>
              <div className={`df mb20 ${styles.dao_item}`}>
                <img width={40} src={icon2}></img>
                <div className={`fs24 fw700 pl20`}>Dao</div>
              </div>
              <p>DFA token holders staking DFA on DeFine can submit proposals and vote on important decisions on the platform including new features, usage of transaction fees, token reward allocations, auction mechanics, etc. Platform governance directly affects how creators and users interact across the platform. Additionally, community governance will facilitate ecosystem development decisions of which will be funded by the DeFine NFT DAO.</p>
            </Col>
          </Row>
        </div>
      </div>

      <Row className={`mb60 ${styles.web30_module}`} data-aos="fade-up" data-aos-duration="1000">
        <Col xs={24} md={8}>
          <div className={styles.common_web30}>
            <div className={styles.common_title}>Web 3.0
              Social Profile
              System</div>
            <div className={`fs18`}>An NFT-based social profile will be constructed for users based on their contribution and achievements in the digital world.</div>
          </div>
        </Col>
        <Col xs={24} md={5}>
          <div className={styles.common_web30_content}>
            <img src={icon3} width={29} height={40}></img>
            <div className={styles.common_title}>DeFine Badge System</div>
            <div>Badge system is the first stepping stone to the DeFine Social Profile System in which eligible users will receive unique NFTs depending on their on-chain activity and behavior. </div>
          </div>
        </Col>
        <Col xs={24} md={6}>
          <div className={styles.common_web30_content}>
            <img src={icon4} width={36} height={40}></img>
            <div className={styles.common_title}>NFT-based Digital Label</div>
            <div>When users connect their wallet address to DeFine platform, their NFT
              based social profile will facilitate access to social activity, private messaging groups,
              content or friends suggestions that will be linked to their digital activity.</div>
          </div>
        </Col>
        <Col xs={24} md={5}>
          <div className={`${styles.common_web30_content}`}>
            <img src={icon5} width={30} height={37}></img>
            <div className={styles.common_title}>Cross Platform Interoperability</div>
            <div >One social identification NFT can be
              interoperable between different blockchains and Metaverse platforms - not restricted
              to DeFine platform.</div>
          </div>
        </Col>
    </Row>
    </div >
  )
};