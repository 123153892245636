import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss"
import landscapeImg1 from '../../assets/images/landing_left.png'
import landscapeImg2 from '../../assets/images/landing_right.png'
import landscapeImg3 from '../../assets/images/landing_right_corner.png'
// import logoSrc from "../../assets/images/logo.png";
import logoName from "../../assets/images/logo_v3.png";
import logoSrc from "../../assets/images/logo_new.png";

export const Landscape = () => {
  const [textHide, setTextHide] = useState(false);
  const [isH5, setIsH5] = useState(false);

  const scrollHeader = () => {
    var scrollTop = document.documentElement.scrollTop;
    if (scrollTop >= 450) {
      setTextHide(true)
    } else {
      setTextHide(false)
    }
  }
  const queryWidth = () => {
    var scrollW = document.documentElement.scrollWidth;
    if (scrollW <768) {
      setIsH5(true)
    } else {
      setIsH5(false)
    }
  }

  useEffect(() => {
    queryWidth()
    window.addEventListener('resize', queryWidth, false);
    window.addEventListener('scroll', scrollHeader, false);
    return () => {
      window.removeEventListener('scroll', scrollHeader, false);
    }
  }, [])

  return (
    <div className={styles.container}>
      {/* <div className={styles.header_fixed}>
        <div className={styles.header_box}>
            {
              isH5?<img width={28} height={26} src={logoSrc} className={styles.headerLogo} onClick={() => {
                window.location.href = 'https://app.define.one'
              }} />:
              <img width={140} height={26} src={logoName} className={styles.headerLogo} onClick={() => {
                window.location.href = 'https://app.define.one'
              }} />
            }
            
          <div className={`${styles.header} ${textHide ? styles.h5_hide : ''}`}>
            <a href="#roadmap">Roadmap</a>
            <a href="#partners">Partners</a>
          </div>
          <button className={`${styles.launch_button} bc_000 c_fff ${textHide ? '' : styles.h5_hide}`} onClick={() => {
            window.location.href = 'https://app.define.one'
          }}>Launch App Now</button>
        </div>
      </div> */}
      <div className={styles.main_text}>
        <h1 className={`fw700`}>
          <div className={styles.block_reveal1}>DeFine<br />The Social NFT <br/> Platform</div>
        </h1>
        {
          !isH5&&<div className="dots">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
        }
        <button className={`${styles.submit_button} bc_000 c_fff`} onClick={() => {
          window.location.href = 'https://app.define.one'
        }}>Launch App Now</button>
      </div>
    </div>
  )
};