import React, { useEffect } from "react";
import styles from "./styles.module.scss";
// import logoFullImg from "./img/logo-full.png";
import logoFullImg from "./img/logo_footer.png";
import { ReactComponent as SvgMd } from "./img/medium.svg"
import iconMd  from "./img/medium.svg"
import iconIns  from "./img/instagram.svg"
import iconTg  from "./img/telegram.svg"
import iconTw  from "./img/twitter.svg"
import iconDs  from "./img/discord.svg"
import iconEmail  from "./img/email.svg"
import { ReactComponent as SvgIns } from "./img/instagram.svg"
import { ReactComponent as SvgTg } from "./img/telegram.svg"
import { ReactComponent as SvgTw } from "./img/twitter.svg"
import { ReactComponent as SvgDs } from "./img/discord.svg"
import { Select } from "antd";
import americaFlag from "./img/america.svg";
import koreaFlag from "./img/south-korea.svg";
import chinaFlag from "./img/chinaFlag.svg";
import japanFlag from "./img/japanFlag.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";


const { Option } = Select;

export const Footer = () => {
  const { t, i18n } = useTranslation();

  const lang = localStorage.getItem('define_lang');
  const config = [
    {
      text: "DeFine",
      children: [
        {
          text: "home",
          href: "https://app.define.one",
        },
        {
          text: "marketplace",
          href: "https://app.define.one/marketplace",
        },
        {
          text: "creator",
          href: "https://app.define.one/creators",
        }
      ]
    }, {
      text: "information",
      children: [
        // {
        //   text: "terms_of_service",
        //   href: "https://docs.de-fine.art/define-a/terms-of-service",
        // },
        // {
        //   text: "privacy_and_policy",
        //   href: "https://docs.de-fine.art/define-a/privacy-and-policy",
        // },
        {
          text: "whitepaper",
          href: lang === 'ko' ? "https://app.define.one/static/media/Define%20White%20Pape%20KR.cdb84813.pdf" : lang === 'zh' ? "https://app.define.one/static/media/Define%20White%20Pape%20CN.0188662f.pdf" : "https://app.define.one/static/media/Define%20White%20Pape%20EN.902c6e52.pdf"
        },
      ]
    },
  ]

  useEffect(() => {
    getLangWhenInit()
  }, [])

  const changeLocale = (selectedLanguage) => {
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem('define_lang', selectedLanguage)
  }

  const getLangWhenInit = () => {
    let storedLang = localStorage.getItem('define_lang')
    if (storedLang && storedLang.length > 0) {
      changeLocale(storedLang)
      return;
    }
    changeLocale(navigator.language.substr(0, 2))
  }

  return (
    <div className={styles.footer_module}>
      <div className={styles.footer}>
        <div className={`${styles.left_area}`}>
          <div className={`${styles.footer_medium} w100\% mb30`}>
            <div>
              <img src={logoFullImg} width={45} height={40} alt="Define" />
            </div>
            <div className={`w100% ${styles.lang_selector}`}>
              <Select className={`${styles.select_wrap} fw500`} value={i18n.language || "Language"} onChange={changeLocale}>
                <Option key="en" value='en'>
                  <img className={`mr10`} src={americaFlag} width='16px' />EN
                </Option>
                <Option key="ko" value='ko'>
                  <img className={`mr10`} src={koreaFlag} width='16px' />한국어
                </Option>
                <Option key="zh" value='zh'>
                  <img className={`mr10`} src={chinaFlag} width='16px' />中文
                </Option>
                <Option key="ja" value='ja'>
                  <img className={`mr10`} src={japanFlag} width='16px' />日本語
                </Option>
              </Select>
            </div>
          </div>
          <div className={`${styles.footer_medium} mb30`}>
            <img src={iconTg}  width={28} height={28} className={"dib mr16 cp"} cursor="pointer" onClick={() => {
              window.open('https://t.me/DeFinePlatform')
            }} />
            {/* <SvgTg width={28} height={28} className={"dib mr36"} cursor="pointer" onClick={() => {
              window.open('https://t.me/DeFinePlatform')
            }} /> */}
            <img src={iconMd} width={28} height={28} className={"dib mr16 cp"} cursor="pointer" onClick={() => {
              window.open('https://medium.com/define-platform')
            }} />
            {/* <SvgMd width={28} height={28} className={"dib mr36"} cursor="pointer" onClick={() => {
              window.open('https://medium.com/define-platform')
            }} /> */}
            <img src={iconTw} width={28} height={28} className={"dib mr16 cp"} cursor="pointer" onClick={() => {
              window.open('https://twitter.com/DeFinePlatform')
            }} />
            {/* <SvgTw width={28} height={28} className={"dib mr36"} cursor="pointer" onClick={() => {
              window.open('https://twitter.com/DeFinePlatform')
            }} /> */}
            <img src={iconIns} width={28} height={28} className={"dib mr16 cp"} cursor="pointer" onClick={() => {
              window.open('https://www.instagram.com/define_nft/')
            }} />
            {/* <SvgIns width={28} height={28} className={"dib mr36"} cursor="pointer" onClick={() => {
              window.open('https://www.instagram.com/define_nft/')
            }} /> */}
            <img src={iconDs} width={28} height={28} className={"dib mr30 cp"} cursor="pointer" onClick={() => {
              window.open('https://discord.gg/UYahw74GdG')
            }} />
            {/* <SvgDs width={28} height={28} className={"dib mr36"} cursor="pointer" onClick={() => {
              window.open('https://discord.gg/UYahw74GdG')
            }} /> */}
            <div className={styles.medium_email}>
              <a className="c_fff" href="mailto:contact@de-fine.art">
              <img src={iconEmail} width={26} height={28} className={"dib mr10"} cursor="pointer" />
              <span className={styles.email_text}>contact@de-fine.art</span></a>
            </div>
          </div>
        </div>
        <div className={`${styles.right_area}`}>
          {
            config.map(item => (
              <div className={`fs14 lh20 mr40  ${styles.feedback_item}`} key={item.text}>
                <li className={'fw600 tal'} children={"fw600"}>{t(item.text)}</li>
                {
                  item.children.map(subItem => (
                    <li className={"mt20 tal"} key={subItem.text}>
                      {
                        subItem.href ? (
                          <a className={"fw400 "} href={subItem.href} target="_blank">{t(subItem.text)}</a>
                        ) : (
                          <Link className={"fw400 c_m"} to={subItem.link}>{t(subItem.text)}</Link>
                        )
                      }
                    </li>
                  ))
                }
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}