import './App.scss';
import './i18n'
import 'sacss'
import "./assets/css/style.scss";
import 'antd/dist/antd.css';
import {BrowserRouter as Router, Route, Redirect} from "react-router-dom";
import { Landscape } from './components/Landscape'
import { DetailBodyV3 } from './components/DetailBodyV3'
import { ConceptSelector} from "./components/ConceptSelector";
import { Introduce} from "./components/Introduce";
import { Footer } from './components/Footer';
import { HeaderNav } from './components/HeaderNav';
import { BackTop} from 'antd';
import bg from "./assets/images/bg.png"

function App() {
  if (window.location.pathname === "/event") {
    window.location.replace("https://app.define.one/event")
  }
  return (
    <>
      <Router>
          <HeaderNav></HeaderNav>
        <div style={{overflow: 'hidden'}} id="container" style={{backgroundImage:`url(${bg})`, backgroundSize:"contain",backgroundRepeat:"no-repeat"}}>
          {/* <BackTop /> */}
          <Landscape></Landscape>
          <DetailBodyV3></DetailBodyV3>
          {/* <ConceptSelector></ConceptSelector> */}
          <Introduce></Introduce>
          <Footer></Footer>
        </div>
      </Router>
    </>
  );
}

export default App;
