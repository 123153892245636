import React, { useEffect, useState } from "react";
import { Row, Col } from "antd"
import { CaretRightOutlined } from '@ant-design/icons';
import AOS from 'aos';
import 'aos/dist/aos.css';

import styles from "./styles.module.scss"
import snk from '../../assets/images/snk.png'
import abyss from '../../assets/images/abyss.png'
import sandbox from '../../assets/images/sandbox.png'
import lau from '../../assets/images/lau.png'
import pearlabyss from '../../assets/images/pearlabyss.png'
import music from '../../assets/images/music.png'
import dragonfly from '../../assets/images/dragonfly.png'
import fbg from '../../assets/images/fbg.png'
import huobi from '../../assets/images/huobi.png'
import kryptos from '../../assets/images/kryptos.png'
import ngc from '../../assets/images/ngc.png'
import seven from '../../assets/images/seven.png'
import dd from '../../assets/images/dd.jpg'
import AboutImg1 from '../../assets/images/about/01.png'
import AboutImg2 from '../../assets/images/about/02.png'
import phone1 from '../../assets/images/phone.png'
import phone2 from '../../assets/images/phone2.png'
import phone3 from '../../assets/images/phone3.png'
import tron from '../../assets/images/tron.png'
import polygon from '../../assets/images/polygon-logo.svg'
import ampleforth from '../../assets/images/ampleforth.png'
import bga from '../../assets/images/bga.webp'
import baikal from '../../assets/images/baikal.jpg'
import intro1 from '../../assets/images/intro1.png'
import intro2 from '../../assets/images/intro2.png'
import intro3 from '../../assets/images/intro3.png'
AOS.init();
export const Introduce = () => {
  const [isH5, setIsH5] = useState(false);
  const queryWidth = () => {
    var scrollW = document.documentElement.scrollWidth;
    if (scrollW < 768) {
      setIsH5(true)
    } else {
      setIsH5(false)
    }
  }
  useEffect(() => {
    queryWidth()
    window.addEventListener('resize', queryWidth, false);
  }, [])
  return (
    <>
      <div className={styles.main_intro}>
        <div className={styles.main_body} data-aos="fade-up" data-aos-duration="1500">
          <div className={styles.row}>
            <div className={`${styles.col_12_w}`}>
              <div className={`${styles.row_title} ${styles.pl40_h5}`}>Social</div>
              <div className={`${styles.row_title} ${styles.pl40_h5} ${styles.title_sp} mb30`}>is at the core of DeFine</div>
              <div className={`${styles.col_box} ${styles.ml10_h5} `}>
                <div className={`${styles.row_subtitle} mb6`}>Wow</div>
                <p>Users can share their “Wow” moments in the form of text, picture and video. These “Wow” moments can be instantly minted to NFTs and share to other social media platforms. </p>
              </div>
              <div className={`${styles.col_box} ${styles.ml10_h5} mt20`}>
                <div className={`${styles.row_subtitle} mb6`}>Personalized Metaverse Profile</div>
                <p>The NFT-based social profile will be utilized in different metaverses where the users activity is reflected among their profile. The NFT-based social profile will pave ways for future NFT-based defi features.</p>
              </div>
            </div>

            <div className={`${styles.col_12_w}`}>
              <img className={styles.cover} src={intro1}></img>
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.nftplus} ${styles.main_body}`} data-aos="fade-up" data-aos-duration="1500">
        <Row>
          <Col xs={{ span: 24, order: 2 }} md={{ span: 12, order: 1 }}>
            <img className={styles.cover} src={intro2}></img>
          </Col>
          <Col xs={{ span: 24, order: 1 }} md={{ span: 12, order: 2 }}>
            <div className={styles.nftPlus_box}>
              <div className={`${styles.row_title} mt20 mb20`}>NFT+</div>
              <div className={`${styles.row_three_title} mb6`}>Assign special features to each NFT</div>
              <div className={`${styles.col_box} ${styles.fm20} mt20`}>
                NFT + expands the application of NFTs by attaching assets to the blockchain and metaverse. They can be redeemed for physical, financial or experiential assets in entertainment, gaming, media, finance and other industries.
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div data-aos="fade-up" data-aos-duration="1500">
      <div className={`${styles.main_c} mt20`} >
        <div className={`${styles.marketplace_module}`}>
          <Row >
            <Col xs={{ span: 24, order: 2 }} md={{ span: 12, order: 1 }}>
              <img className={styles.introImg} src={intro3}></img>
            </Col>
            <Col xs={{ span: 24, order: 1 }} md={{ span: 12, order: 2 }}>
              <div className={styles.content_box}>
                <div className={`${styles.row_title} mb10`}>Marketplace</div>
                <div className={`fs18 fw600`}>DeFine marketplace supports multi-chain and standards interoperability, including Ethereum, Polygon, BSC, Tron Network and standards like ERC-721 & ERC-1155.</div>
              </div>
              <div className={styles.content_box}>
                <div className={`${styles.row_subtitle} mb10`}>NFT Discover Page</div>
                <div>The NFT Discover Page is a detailed NFT Display interface to show specific NFT description, attributes, on-chain information, transaction records, etc. It will support social features for NFT lovers to like, save, share, comment and even make offers for each NFT. </div>
              </div>
              <div className={styles.content_box}>
                <div className={`${styles.row_subtitle} mb10`}>NFT Exhibition Hall</div>
                <div>The Exhibition Hall is a decentralized storage interface where creators and collectors can exhibit their NFTs forever without an immediate sale purpose. This is a way for creators to grow their following and increase their exposure outside of their existing communities. This interface is separate from the individual customized creator pages that creators have on the platform.</div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      </div>


      {/* <div id="roadmap" className={styles.roadmap} data-aos="fade-up" data-aos-duration="1500">
        <div className={styles.roadmap_body}>
          <div className={`${styles.feature}`}>Roadmap</div>
          <ul className={styles.roadmap_box}>
            <li>
              {
                !isH5 && <div className={styles.roadmap_title}>2022 Q1 and previous</div>
              }
              <div className={styles.dot_box}>
                <div className={styles.dot_bg}>
                  <div className={styles.dot}></div>
                </div>
                <div className={styles.line}></div>
              </div>
              {
                isH5 && <div className={styles.roadmap_title}>2022 Q1 and previous</div>
              }
              <ul className={styles.roadmap_text}>
                <li>Launch GameFi NFT Marketplace</li>
                <li>Launch On-chain Governance</li>
                <li>Genesis DeFine Badge Drop</li>
                <li>Launch DeFine DAO Treasury</li>
              </ul>
            </li>
            <li>
              {
                !isH5 && <div className={styles.roadmap_title}>2022 Q2</div>
              }
              <div className={styles.dot_box}>
                <div className={styles.dot_bg}>
                  <div className={styles.dot}></div>
                </div>
                <div className={styles.line}></div>
              </div>
              {
                isH5 && <div className={styles.roadmap_title}>2022 Q2</div>
              }
              <ul className={styles.roadmap_text}>
                <li>Activate DeFine Social System (Topic)</li>

                <li>Event messaging function</li>

                <li>GameFi NFT marketplace</li>
              </ul>
            </li>
            <li>
              {
                !isH5 && <div className={styles.roadmap_title}>2022 Q3</div>
              }
              <div className={styles.dot_box}>
                <div className={styles.dot_bg}>
                  <div className={styles.dot}></div>
                </div>
                <div className={styles.line}></div>
              </div>
              {
                isH5 && <div className={styles.roadmap_title}>2022 Q3</div>
              }
              <ul className={styles.roadmap_text}>
                <li>Launch Ranking and Level system</li>

                <li>Launch DeFine Social V2.0 </li>
              </ul>
            </li>
            <li>
              {
                !isH5 && <div className={styles.roadmap_title}>2022 Q4 and future</div>
              }
              
              <div className={styles.dot_box}>
                <div className={styles.dot_bg}>
                  <div className={styles.dot}></div>
                </div>
                <div className={styles.line}></div>
                {
                  isH5?<CaretRightOutlined rotate={90} style={{ fontSize: '22px', color: '#000' }}></CaretRightOutlined>:
                  <CaretRightOutlined style={{ fontSize: '22px', color: '#000' }}></CaretRightOutlined>
                }
              </div>
              {
                isH5 && <div className={styles.roadmap_title}>2022 Q4 and future</div>
              }
              <ul className={styles.roadmap_text}>
                <li>Launch DeFine Arcade</li>
                <li>Launch DeFine Mini Game Studio</li>
              </ul>
            </li>
          </ul>
        </div>
      </div> */}

      <div className={styles.partners} data-aos="fade-up" data-aos-duration="1500">
        <div id="partners" className={styles.partners_body}>
          <div className={`${styles.feature} mb30`}>Partners</div>
          <div className={styles.logo_box}>
            <div><a href="https://3lau.com/" target="_blank"><img src={lau} /></a></div>
            <div><a href="http://abysscompany.com/" target="_blank"><img src={abyss} /></a></div>
            <div><a href="https://tron.network" target="_blank"><img src={tron} /></a></div>
            <div><a href="https://" target="_blank"><img src={baikal} /></a></div>
            <div><a href="https://" target="_blank"><img src={bga} /></a></div>
            {/* <div><a href="https://www.dcp.capital/" target="_blank"><img className={styles.bd_img} src={dragonfly} /></a></div> */}
            <div><a href="https://draperdragon.com/home-en" target="_blank"><img src={dd} /></a></div>
            {/* <div><a href="https://www.fbg.capital/" target="_blank"><img src={fbg} /></a></div> */}
            <div><a href="https://www.huobi.com/" target="_blank"><img src={huobi} /></a></div>
            <div><a href="https://kryptos.fund/" target="_blank"><img src={kryptos} /></a></div>
            <div><a href="https://www.mymusictaste.com/" target="_blank"><img src={music} /></a></div>
            <div><a href="https://ngc.fund/" target="_blank"><img style={{ width: 200 }} src={ngc} /></a></div>
            <div><a href="https://www.pearlabyss.com/" target="_blank"><img src={pearlabyss} /></a></div>
            <div><a href="https://polygon.technology/" target="_blank"><img src={polygon} /></a></div>
            <div><a href="http://en.sandbox.co.kr/" target="_blank"><img src={sandbox} /></a></div>
            <div><a href="http://www.7xvc.com/" target="_blank"><img src={seven} /></a></div>
            {/* <div><a href="https://www.snk-corp.co.jp/" target="_blank"><img src={snk} /></a></div> */}
            <div><a href="https://www.ampleforth.org/" target="_blank"><img src={ampleforth} style={{ width: 50 }} /></a></div>

          </div>
        </div>
      </div>
    </>
  )
};